import React from 'react'

import SEO from 'components/seo'
import Layout from 'components/layouts/layout'
import Statement from 'components/pages/advantage/advantage-statement'
import Advantages from 'components/pages/advantage/advantages'

export default ({ location }) => {
  return (
    <Layout title="Advantage" fullWidth={true}>
      <SEO title="Advantage of Little Scholar" path={location.pathname} />

      <Statement />
      <Advantages />
    </Layout>
  )
}
