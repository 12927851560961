import React from 'react'

import Little from 'components/svgs/little'

export default () => {
  return (
    <section className="container mx-auto px-4">
      <h2 className="text-center mb-4 text-3xl md:text-5xl">
        <span>Embrace the Future with</span> <br />
        <span className="flex flex-row justify-center items-baseline text-4xl md:text-6xl">
          <Little className="inline h-8 lg:h-14 w-auto pr-4" />
          <span>SCHOLAR</span>
        </span>
      </h2>

      <p className="max-w-4xl mx-auto leading-7">
        At Little Scholar, we believe that a child learns best through play. Our
        curriculum helps children master the English language in natural
        learning opportunities. Students will utilize critical thinking and
        reasoning skills to make hypothesis’ and observations while effectively
        communicating the new knowledge they have acquired. Little Scholar
        creates value in education by ensuring that our students can confidently
        apply what they have learned in every aspect of their daily lives.
      </p>
    </section>
  )
}
