import React from 'react'

import Atom from 'static/svgs/advantages/atom.svg'
import Book from 'static/svgs/advantages/book.svg'
import Flag from 'static/svgs/advantages/flag.svg'
import Meeting from 'static/svgs/advantages/meeting.svg'
import People from 'static/svgs/advantages/people.svg'
import Star from 'static/svgs/advantages/star.svg'

export default () => {
  const data = info

  return (
    <section className="my-10 md:my-20 bg-primary-200">
      <div className="px-4 py-6 container mx-auto flex flex-col md:grid grid-rows-3 grid-flow-col gap-x-8 gap-y-10">
        {data.map(({ icon, title, description }) => (
          <div
            className="mb-8 md:mb-0 last:mb-0 text-center md:text-left"
            key={title}
          >
            <img
              src={icon}
              className="h-8 md:h-10 mb-2 mx-auto md:mx-0"
              alt=""
            />
            <h3 className="mb-3 text-xl md:text-2xl font-bold">{title}</h3>
            <p>{description}</p>
          </div>
        ))}
      </div>
    </section>
  )
}

const info = [
  {
    icon: People,
    title: 'Small Class Size',
    description:
      'Little Scholar encourages small class size as it allows for better teacher engagement and modeling. With the increased amount of attention available for each student, this allows greater academic interactions within the class. With smaller class sizes, there are more opportunities for growth in learning and achieving academic excellence.',
  },
  {
    icon: Book,
    title: 'Strong Curriculum Specialists',
    description:
      'With a wealth of experience in pre-school education and holistic development of children, the English curriculum is designed and overseen by experienced curriculum specialists from Pennsylvania, USA. The team is also headed by a director of curriculum with the Ministry of Education in Pennsylvania, USA.',
  },
  {
    icon: Flag,
    title: 'Nurtured by America’s Best',
    description:
      'Our curriculum is presented by American Baccalaureate, and had been designed by veteran American teachers with over 40 years experience.',
  },
  {
    icon: Meeting,
    title: 'Experienced Academic Support',
    description:
      'Little Scholar’s team is comprised of school principals and heads of department in USA with over 40 years of experience in managing schools, departments and developing staff. The team will assist to carefully select future franchises to implement the Little Scholar curriculum into a variety of foreign countries that our curriculum goals of nurturing well-rounded English speakers are met.',
  },
  {
    icon: Atom,
    title: 'Holistic Integrated Approach',
    description:
      'Our curriculum incorporates elements of STREAM (Science, Technology, Engineering, and Mathematics) to develop well-rounded and interdisciplinary individuals. ',
  },
  {
    icon: Star,
    title: 'Immersed in American Culture',
    description:
      'Our curriculum focuses on fostering confidence, logical thinking, and social skills. Students will get to immerse in a the American culture which is incorporated into the engaging learning activities presented by Little Scholar curriculum.',
  },
]
